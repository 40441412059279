<template>
    <div class="dash-container">
        <h1>
        MY VAULT
        </h1>
        
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <div class="has-text-centered">
          <table class="banner-table has-text-centered">
          <img src="@/assets/mask.svg" width="400px">
          </table>
        </div>
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <center><p>Welcome to your InstaCrypt Vault Dashboard. This is where you will manage your contacts that you want to securely share files with.</p></center>
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <div class="stats-table">
          <div class="stats-option default-option">
              <h1># of Authorizations</h1>
              <hr />
              <div class="stats">
                  <div class="stat-numb">
                      <span class="stats"><b>{{ this.user_count }}</b></span>
                  </div>
              </div>
              <div class="butt">
                  <router-link :to="{ name: 'MyApp' }">
                      <a href="#" class="button">Authorize App</a>
                  </router-link>
              </div>
          </div>
          
          <div class="stats-option default-option">
              <h1># of Contacts</h1>
              <hr />
              <div class="stats">
                  <div class="stat-numb">
                      <span class="stats"><b>{{ this.con_count }}</b></span>
                  </div>
              </div>
              <div class="butt">
                  <router-link :to="{ name: 'MyContacts' }">
                      <a href="#" class="button">View My Contacts</a>
                  </router-link>
              </div>
          </div>
          
          <div class="stats-option default-option">
              <h1># of Requests</h1>
              <hr />
              <div class="stats">
                  <div class="stat-numb">
                        <span class="stats"><b>{{ this.req_count }}</b></span>
                  </div>
              </div>
              <div class="butt">
                  <router-link :to="{ name: 'MyRequests' }">
                      <a href="#" class="button">View My Requests</a>
                  </router-link>
              </div>
          </div>
        </div>
        <span v-if="instruct==true">
            <VuePureLightbox class="lightbox">
                <div class="has-text-center">
                    <table class="instruct-form has-text-centered">
                    <tr>
                        <td class="instruct-header">
                            <p class="org-description is-size-5"></p>
                        </td>
                    </tr>
                    <tr>
                        <td class="instruct-content">
                            <div><img class="center-image" src="../../assets/smiley-lock.svg" width="100px"></div>
                            
                            <br>
                            <p class="org-description is-size-6 instructions">It looks like you haven't downloaded or authorized the InstaCrypt app yet. Check out the "App Access" section to get this done. It only takes a few minutes!</p>
                            <br>
                            <button class="button submit is-primary is-outlined" v-on:click="gotoAA()">Let's Go!</button>
                            <br>
                            <p class="secondary"><a href="#" class="secondary" v-on:click="later()">Maybe Later...</a></p>

                        </td>
                    </tr>
                    </table>
                </div>
            </VuePureLightbox>
        </span>     
    </div>
</template>
<script>
export default {
    data() {
      return {
        user_count: 0,
        con_count: 0,
        req_count: 0,
        instruct: 0,
      }
    },
    created() {
      this.getAppCount();
      this.getConCount();
      this.getReqCount();
    },
    methods: {
      getAppCount() { 
        this.$store.dispatch('auth/get_app_count').then(
        (response) => {
                console.log(response);
                if (response.data.status == true) {
                    this.user_count = response.data.data;
                    if (this.user_count == 0) {
                        this.instruct = true;
                    }
                } else {
                  console.log("Can't talk to the API endpoint");
                  this.user_count = "Failed API Call...";
                }
            },
            error => {
                this.user_count = "Server Error";
                console.log(error);
            }
        );
      },
      getConCount() { 
        this.$store.dispatch('auth/get_con_count').then(
        (response) => {
                console.log(response);
                if (response.data.status == true) {
                    console.log("if loop: ");
                    console.log(response.data.data);
                    this.con_count = response.data.data;
                } else {
                  console.log("Can't talk to the API endpoint");
                  this.con_count = "Failed API Call...";
                }
            },
            error => {
                this.con_count = "Server Error";
                console.log(error);
            }
        );
      },
      getReqCount() { 
        this.$store.dispatch('auth/get_req_count').then(
        (response) => {
                console.log(response);
                if (response.data.status == true) {
                    console.log("if loop: ");
                    console.log(response.data.data);
                    this.req_count = response.data.data;
                } else {
                  console.log("Can't talk to the API endpoint");
                  this.req_count = "Failed API Call...";
                }
            },
            error => {
                this.req_count = "Server Error";
                console.log(error);
            }
        );
      },
      gotoAA() {
          this.instruct = 0;
          this.$router.push({path: "/dashboard/appaccess"});
      },
      later() {
          this.instruct = 0;
      }
    }
}
</script>
<style lang='scss' scoped>
.lightbox {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, .8);
}
h1 {
    font-weight: bold;
}
pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.logo {
  padding-top: 60px;
}
.banner-table {
  background-color: #f5f5f5;
  width: 100%;
}
// Stats table
$icon-size: 3rem;
$icon-color: #D8D8D8;
$main-color: #aac734;
.stats {
    width: 100%;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 10px;
}
.stats-table {
    display: inline-block;
    background: #ffffff;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    .default-option {
        box-shadow: 0px 2px 30px rgba(0,0,0,0.05);
        transform: scale(1.04);
    }    
    .stats-option {
        padding-top: 20px;
        width: 32%;
        background:#ffffff;
        float: left;
        text-align: center;
        transition: all .3s ease-in-out;
        &:nth-child(even) {
            margin: 0 2%;
        }
        &:hover {
            cursor: pointer;
            box-shadow: 0px 2px 30px rgba(0,0,0,0.2);
            transform: scale(1.04);
            i, span {
                color: $main-color;
            }
        }
        .butt {
                opacity: 1 !important;
                visibility: visible !important;
                a.button {
                    transform: translateY(0px) !important;
                }
            }
        hr {
            border:none;
            border-bottom: 1px solid #F0F0F0;
        }
        i {
            font-size: $icon-size;
            color: $icon-color;
            transition: all .3s ease-in-out;
        }
        h1 {
            margin: 10px 0;
            color: $main-color;
            transition: all .3s ease-in-out;
        }
        p {
            color: #999;
            padding: 0 10px;
            line-height: 1.3;
        }
        .stats {
            position: relative;
            background: #ffffff;
            .stat-numb {
                margin-top: 10px;
                margin-bottom: 10px;
                span.stats {
                    font-size: 5rem;
                    text-transform: uppercase;
                    display: block;
                    font-weight: 700;
                    position: relative;
                    b {
                        position: relative;
                        font-size: 3rem;
                        font-weight: 600;
                    }
                }
            }
        }
        
        .butt {
                padding-top: 20px;
                padding-bottom: 20px;
                opacity: 0;
                visibility: visible;
                transition: all .3s ease-in-out;
                a.button {
                    background: #ffffff;
                    color: $main-color;
                    border-color: $main-color;
                    &:hover {
                        background: $main-color;
                        color: white;
                    }
                }
            }
    }
}
.instructions {
    color: white;
}
.secondary {
    margin-top: 15px;
    font-size: 12px;
    color: #bfbfbf;
}
.secondary:hover {
    color: white;
}

// Responsive
@media screen and (max-width: 500px) {
    .stats-table {
        .stats-option {
            padding: 5%;
            width: 90%;
            &:nth-child(even) {
                margin: 30px 0 !important;
            }
        }
    }
}

</style>